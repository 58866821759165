import React, { useState, useRef, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import axios from "axios";
import "./Home.css";
import MotionButton from '../motion-button/index';

const Home = () => {
  const TIMEOUT = 2 * 60000; //2 minutes
  let timeoutId = useRef();
  const loopVideoRef = useRef(null);
  const responseVideoRef = useRef(null);

  const [videoInQueue, setVideoInQueue] = useState(undefined);
  const [isLoading, setAsLoading] = useState(false);
  const [videoName, setVideoName] = useState("welcome.mp4");
  const [responseUrl, setResponseUrl] = useState("https://www.hero.com");

  const [isResponseVideoVisible, setResponseVideoVisible] = useState(true);
  const [isOverlayVisible, setOverlayVisible] = useState(true);

  useEffect(() => {
    if (isOverlayVisible) return;
    responseVideoRef.current.play();
  }, [isOverlayVisible]);


  // Sends the message to the server
  const sendDialogToServer = async (message) => {
    setResponseUrl("");
    setAsLoading(true);
    if (message.trim() !== "") {
      if (timeoutId.current) clearTimeout(timeoutId.current);
      console.log("Captured text : ", message);
      const res = await axios.post(
        "https://vidchatapi.herokuapp.com/text-input",
        {
          message: message,
        }
      );
      console.log(res.data.data);
      const response = res.data.data[0].queryResult.fulfillmentText.split(";");
      const responseVideoName = response[0] + ".mp4";
      const responseUrlName = response[1];
      console.log("Video to be played : ", responseVideoName);
      console.log("url : ", responseUrlName);
      setVideoInQueue(responseVideoName);
      if (responseVideoName === 'video5.mp4') {
        setResponseUrl("https://" + responseUrlName);
        setAsLoading(false);
      } else {
        if (responseUrlName) {
          if (responseUrlName?.split(".")[1] === "google") {
        setResponseUrl("https://www.google.com/webhp?igu=1");
            setAsLoading(false);
          } else {
            setResponseUrl("https://" + responseUrlName);
            setAsLoading(false);
          }
        }
      }
    }
  };


  function openFullscreen() {

    // var elem = document.getElementsByTagName("body");
    var elem = document.body;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      // }
      if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  }

  const commands = [
    {
      command: "*",
      callback: (message) => sendDialogToServer(message),
    },
  ];
  const { listening, browserSupportsSpeechRecognition } = useSpeechRecognition({
    commands,
  });


  useEffect(() => {
    if (isOverlayVisible) return;
    responseVideoRef.current.play();
  }, [isOverlayVisible]);

  useEffect(() => {
    if (timeoutId.current) clearTimeout(timeoutId.current);

    console.log(`I'm ${!listening ? "not" : ""} listening`);

    if (!listening) return;
    timeoutId.current = setTimeout(() => {
      const responseVideoName = "thankyou.mp4";
      console.log("Video to be played : ", responseVideoName);
      setVideoInQueue(responseVideoName);
    }, TIMEOUT);
  }, [listening, TIMEOUT]);

  // Checks browser compatibility
  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div className="main">
      <div>
        {/* Loop video plays indefinitely in background */}
        <video
          className="video"
          ref={loopVideoRef}
          muted
          onPlay={() => console.log("Playing loop.mp4")}
          onEnded={() => {
            //If no video is in queue we loop the video again from 0th second
            if (!videoInQueue) {
              // loopVideoRef.current.currentTime = 0;
              loopVideoRef.current.play();
              return;
            }

            //If there is a video in queue we set that to be played and make the response visible
            setVideoName(videoInQueue);
            setVideoInQueue(undefined);
            setResponseVideoVisible(true);
            responseVideoRef.current.load();
            SpeechRecognition.stopListening();
            responseVideoRef.current.play();
          }}
        >
          <source src="/videos/loop.mp4" type="video/mp4" />
        </video>

        {/* Loading the response video, initially the video name is welcome.mp4 */}
        <video
          className="video"
          ref={responseVideoRef}
          onPlay={() => console.log(`Playing ${videoName}`)}
          onEnded={() => {
            // To reset the loop video to 0th second
            loopVideoRef.current.currentTime = 0;
            loopVideoRef.current.play();
            if (videoName !== "thankyou.mp4")
              SpeechRecognition.startListening({ continuous: true });
            else {
              console.log("I won't listen anymore");
              window.location.reload();
            }

            setVideoName(undefined);
            setResponseVideoVisible(false);
          }}
          style={{ display: isResponseVideoVisible ? "block" : "none" }}
        >
          <source src={`/videos/${videoName}`} type="video/mp4" />
        </video>
      </div>

      {/* webpage frame */}
      <div className="webpage">
        <div style={isLoading ? { displaly: 'block', height: '100%' } : { display: 'none' }}>
          <div className="webpageLoading">
            <div className="loader">
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
              <div className="bar4"></div>
              <div className="bar5"></div>
              <div className="bar6"></div>
            </div>
          </div>
        </div>
        {/* {
            responseUrl ?  */}
        <iframe
          src={responseUrl}
          height="100%"
          width="100%"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          sandbox="allow-same-origin allow-scripts allow-popups"
          loading="lazy"
          scrolling="no"
          id="iframeId"
          className="iframeWebsite"
          title={responseUrl}
          style={!isLoading ? { displaly: 'block' } : { display: 'none' }}
        ></iframe>
        {/* :
          <h1>Say something for searching</h1>
          } */}
      </div>

      {isOverlayVisible && (
        <div className="overlay">
          {/* <img 
          onClick={() => setOverlayVisible(false)}
            height="100px"
            style={{
              cursor: "pointer",
            }}
            src={buttonBg}
            alt="button"
            className="filter-white"
          /> */}
          <div onClick={() => { setOverlayVisible(false); openFullscreen(); }}>
            <MotionButton />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
